import Cookies from "js-cookie";

const app = {
  state: {
    device: "desktop",
    language: Cookies.get("language") || "de",
    size: {
      screen: {
        width: 0,
        height: 0
      },
    },
    mainnav: [],
    navLevel: 0,
    navVisible: false,
    footernav: [],
    pageOverflow: false,
    searchForm: "",
    searchVisible: false,
    searchResult: "",
    showSearchResult: false,
    searchFormAction: ""
  },
  mutations: {
    TOGGLE_DEVICE: (state, device) => {
      state.device = device;
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language;
      Cookies.set("language", language);
    },
    SET_SIZE: (state, size) => {
      state.size.screen.width = size.screen.width;
      state.size.screen.height = size.screen.height;
    },
    SET_MAINNAV: (state, nav) => {
      state.mainnav = nav;
    },
    SET_NAVLEVEL: (state, level) => {
      state.navLevel = level;
    },
    SET_NAVVISIBLE: (state, value) => {
      state.navVisible = value;
    },    
    SET_PAGEOVERFLOW: (state, overflow) => {
      state.pageOverflow = overflow;
    },
    SET_SEARCHFORM: (state, value) => {
      state.searchForm = value;
    },
    SET_SEARCHVISIBLE: (state, value) => {
      state.searchVisible = value;
    },
    SET_SEARCHRESULT: (state, value) => {
      state.searchResult = value;
    },
    SET_SHOWSEARCHRESULT: (state, value) => {
      state.showSearchResult = value;
    },
    SET_SEARCHFORMACTION: (state, value) => {
      state.searchFormAction = value;
    }
  },
  actions: {
    toggleDevice({ commit }, device) {
      commit("TOGGLE_DEVICE", device);
    },
    setLanguage({ commit }, language) {
      commit("SET_LANGUAGE", language);
    },
    setSize({ commit }, size) {
      commit("SET_SIZE", size);
    },
    setMainnav({ commit }, nav) {
      commit("SET_MAINNAV", nav);
    },
    setNavLevel({ commit }, level) {
      commit("SET_NAVLEVEL", level);
    },
    toggleNav({ commit }) {
      commit("SET_SEARCHVISIBLE", false);
      commit("SET_NAVVISIBLE", !app.state.navVisible);
    },
    setSearchForm({ commit }, value) {
      commit("SET_SEARCHFORM", value);
    },
    toggleSearch({ commit }) {
      commit("SET_NAVVISIBLE", false);
      commit("SET_SEARCHVISIBLE", !app.state.searchVisible);
    },
    setSearchResult({ commit }, value) {
      commit("SET_SEARCHRESULT", value);
    },
    showSearchResult({ commit }, value) {
      commit("SET_SHOWSEARCHRESULT", value);
    },
    setSearchFormAction({ commit }, value) {
      commit("SET_SEARCHFORMACTION", value);
    }
  }
};

export default app;
