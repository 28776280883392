import Vue from "vue";
import Router from "vue-router";
import Layout from "@/views/layout/";

Vue.use(Router);

export const constantRouterMap = [
  {
    path: "",
    component: Layout,
    redirect: "home",
    meta: {
      title: "Home",
      uid: 1,
      display: 0,
      type: "mainnav",
      dataType: "html",
      hasOverflow: false
    },
    children: [
      {
        path: "/home",
        component: () => import("@/views/home/index"),
        name: "home",
        meta: {
          title: "Home",
          uid: 1,
          noCache: true,
          type: "mainnav",
          display: 0,
          dataType: "html",
          hasOverflow: false
        }
      }
    ]
  }
];

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: constantRouterMap
});

export const defaultAsyncRouterMap = [
  {
    path: "/about-wispowo",
    component: Layout,
    redirect: "index",
    meta: {
      title: "About Wispowo",
      noCache: true
    },
    children: [
      {
        path: "/about-wispowo",
        component: () => import("@/views/home/index"),
        name: "about-wispowo",
        meta: {
          title: "About Wispowo",
          noCache: true
        }
      }
    ]
  },
  { path: "*", redirect: "/404", hidden: true }
];
