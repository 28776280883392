import { Api } from "@/api";
import axios from "axios";
import Store from "@/store";

const CheckLogin = {
  check() {
    const uri = Api.BACKEND_URL + Api.CHECKLOGIN;
    axios
      .create({ withCredentials: true })
      .get(uri)
      .then(response => {
        if (response.data.success) {
          if (response.data.user && response.data.user.uid) {
            Store.dispatch("setLoginChecked", true);
            Store.dispatch("setLogin", true);
            Store.dispatch("setUser", response.data.user);
          }
        }
        /* const dynRoutes = response.data.menu;
         Store.dispatch("GenerateRoutes", { routes: dynRoutes, nav: "productnav" } ).then(() => {
           router.addRoutes(Store.getters.gAddRouters);
           this.productnavItems = this.gPermission_routers;
         }); */
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => (this.isLoading = false));
  }
};

export default CheckLogin;