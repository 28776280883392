<template>
  <nav role="navigation" aria-label="Main" :class="mainNavClass" :data-level-active="activeLevel">
    <ul class="nav__items nav__items--main" :data-level-active="activeLevel">
      <li
        v-for="route in displayRoutes"
        v-bind:key="route.meta.pageId"
        class="nav__item nav__item--main"
        :data-level="route.meta.level"
        :class="{'is-active': isActive(route), 'is-last': isLast(route), 'is-show': isParent(route), 'is-home': isHome}"
      >
        <RouterLink
          :to="route.path"
          :linkClass="linkClass"
          :uid="route.meta.pageId"
          :level="route.meta.level"
          @onLinkClicked="onLinkClicked"
        >
          <i v-if="route.meta.svgIcon" class="svgicon svgicon--page" v-html="route.meta.svgIcon" ></i><span class="navlink__title" v-html="route.meta.title"></span>
        </RouterLink>
        <SubNav :items="route.children" :parent="route" :activeLevel="activeLevel" />
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
//import { EventBus } from "@/bus/event-bus.js";
import RouterLink from "@/components/RouterLink";
import SubNav from "./SubNav";

export default {
  name: "MainNav",
  components: {
    RouterLink,
    SubNav
  },
  data() {
    return {
      linkClass: "nav__link nav__item--link"
    };
  },
  computed: {
    ...mapGetters(["gNavVisible"]),
    displayRoutes() {
      return this.items ? this.items.filter( item => item.meta && item.meta.display && item.meta.type == "mainnav" ) : [];
    },
    navitemLines() {
      if (this.displayRoutes && this.displayRoutes.length > 5) {
        return true;
      } else {
        return false;
      }
    },
    matched() {
       return this.$route.matched.map(item => item.meta.pageId);
    },
    routeLevel() {
      return this.$route.meta.level;
    },
    mainNavClass() {
      return (this.gNavVisible?"is-visible":"");
    },
    isHome() {    
      return this.items.filter(item => item.state.active == true).length == 0;
    }
  },
  props: {
    items: {
      type: Array,
      default: null
    },
    activeLevel: Number
  },
  methods: {
    isActive(route) {
      return (route.state && route.state.active?true:false);
    },
    isLast(route) {
      return (route.state && route.state.last?true:false);
    },
    isParent(route) {
      return (route.state && route.state.parent?true:false);
    },
    onLinkClicked(/* level, uid, to */) {
      this.$store.dispatch("toggleNav");
    }
  }
};
</script>
