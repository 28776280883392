		12.6,30.1 12.6,18.5 21.4,18.5 21.4,30.1 	"/>

<template>
  <nav role="navigation" aria-label="Footer" :class="{ 'is-active': isNavVisible || isSearchVisible }">
    <ul class="nav__items nav__items--footer">
      <li class="nav__item nav__item--footer">
        <a href="#" title="Menü" class="nav__link nav__link--footer" @click="onHomeLinkClick">
          <i class="svgicon svgicon--home">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 34 31.1" overflow="visible" enable-background="new 0 0 34 31.1" xml:space="preserve"><g><polyline fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="12.6,30.1 12.6,18.5 21.4,18.5 21.4,30.1 	"/><polygon fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="3.9,15.5 3.9,30.1 30.1,30.1 30.1,15.5 33,15.5 17,1 1,15.5 	"/></g></svg>
          </i>
          <span class="navitem__title">Startseite</span>
        </a>
      </li>    
      <li class="nav__item nav__item--footer">
        <a href="#" title="Menü" class="nav__link nav__link--footer" @click="onListLinkClick">
          <i class="svgicon svgicon--list" v-if="!isNavVisible">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 34 21.2" overflow="visible" enable-background="new 0 0 34 21.2" xml:space="preserve"><g><line fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1" y1="1" x2="33" y2="1"/><line fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1" y1="10.6" x2="33" y2="10.6"/><line fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1" y1="20.2" x2="33" y2="20.2"/></g></svg>
          </i>
          <i class="svgicon svgicon--close" v-else>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 24.6 24.6" overflow="visible" enable-background="new 0 0 24.6 24.6" xml:space="preserve"><g><line fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1" y1="23.6" x2="23.6" y2="1"/><line fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1" y1="1" x2="23.6" y2="23.6"/></g></svg>
          </i>
          <span class="navitem__title">Menü</span>
        </a>
      </li>
      <li class="nav__item nav__item--footer">
        <a href="#" title="Menü" class="nav__link nav__link--footer" @click="onSearchLinkClick">
          <i class="svgicon svgicon--search">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 34 34" overflow="visible" enable-background="new 0 0 34 34" xml:space="preserve"><g><path fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M1,14.1c0,7.2,5.9,13.1,13.1,13.1c7.2,0,13.1-5.9,13.1-13.1C27.2,6.9,21.3,1,14.1,1C6.9,1,1,6.9,1,14.1z"/><line fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="33" y1="33" x2="23.3" y2="23.3"/><path fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M15.1,7c-4.3,0-7.8,3.5-7.8,7.8"/></g></svg>
          </i>
          <span class="navitem__title">Suche</span>
        </a>
      </li>      
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import NavigationUtility from "@/utils/NavigationUtility/";

export default {
  name: "FooterNav",
  components: { },
  data() {
    return {
      linkClass: "nav__link nav__item--link",
    };
  },
  computed: {
    ...mapGetters(["gNavVisible", "gSearchVisible", "gAddRouters"]),
    isNavVisible() {
      return this.gNavVisible;
    },
    isSearchVisible() {
      return this.gSearchVisible;
    }
  },
  props: {
    items: {
      type: Array,
      default: null
    }
  },
  methods: {
    onHomeLinkClick(ev) {
      if (this.isSearchVisible) this.$store.dispatch("toggleSearch");
      if (this.$route.path != "/home") this.$router.push("/home");
      ev.preventDefault();
    },    
    onListLinkClick(ev) {
      NavigationUtility.getActiveEntry(this.items, this.$route);
      this.$store.dispatch("toggleNav");
      ev.preventDefault();
    },
    onSearchLinkClick(ev) {
      this.$store.dispatch("toggleSearch");
      ev.preventDefault();
    }
  }
};
</script>
