<template>
  <div :class="classObj" class="app-wrapper" :style="wrapperStyle">
    <HeaderBack :items="mainnavItems" />
    <MainNav :items="mainnavItems" :activeLevel="activeNavLevel" />
    <div class="main-container" :style="mainContainerStyle">
      <app-main />
    </div>
    <Search />
    <FooterNav :items="mainnavItems" />
    <Popup :show="showSearch" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AppMain, MainNav, FooterNav, HeaderBack, Search, Popup} from "./components";
import { EventBus } from "@/bus/event-bus.js";
import { Api } from "@/api";
import axios from "axios";
import initResize from "@/utils/resize";
import NavigationUtility from "@/utils/NavigationUtility/";

export default {
  name: "Layout",
  components: {
    AppMain,
    MainNav,
    FooterNav,
    HeaderBack,
    Search,
    Popup
  },
  data() {
    return {
      fullScreenMode: false,
      scrollWheel: {
        deltaY: 0,
        keys: {
          alt: false,
          ctrl: false,
          shift: false
        },
        keyCode: 0,
        pageX: 0,
        srcElement: 0
      },
      rAFScrollTicking: false,
      changeUserProfileVisible: false,
      changePasswordVisible: false,
      productnavItems: [],
      contents: {
        appFooter: ""
      },
      loginFormVisible: true,
      showMainNav: false,
      popupContent: "",
      showSearch: false
    };
  },
  computed: {
    ...mapGetters(["gPermission_routers", "gSize", "gMainnav", "gNavLevel"]),
    device() {
      return this.$store.state.app.device;
    },
    classObj() {
      return {
        mobile: this.device === "mobile"
      };
    },
    mainContainerStyle() {
      return this.fullScreenMode ? "margin-left: 0; " : "";
    },
    mainnavItems() {
      var routers = this.gPermission_routers;
      NavigationUtility.getActiveEntry(routers, this.$route);
      NavigationUtility.navItems = routers;
      return routers;
    },
    activeNavLevel() {
      return NavigationUtility.getActiveMenuLevel(this.gPermission_routers, this.$route);
    },
    wrapperStyle() {
      return ""; //(this.$store.state.app.pageOverflow?"height: auto;":"height: " + this.gSize.screen.height + "px; ");
    }
  },
  created() {
    EventBus.$on("doResize", () => {
      this.checkResize();
    });
    initResize();
  },
  mounted() {
    this.checkResize();
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch("closeSideBar", { withoutAnimation: false });
    },
    checkResize() {
      this.$store.dispatch("setSize", {
        screen: {
          width: this.getWindowWidth(),
          height: this.getWindowHeight()
        }
      });
    },
    getWindowWidth() {
      return ( window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      );
    },
    getWindowHeight() {
      return ( window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
    },
    scrollUpdate() {
      this.rAFScrollTicking = false;
      EventBus.$emit("onScrollWheel", this.scrollWheel);
    },
    handleLogout() {
      const postData = "logintype=logout";
      axios
        .post(Api.BACKEND_URL + Api.LOGIN, postData)
        .then(response => {
          //LoaderMainnav.get();
          this.testResponse = response;
          this.$store.dispatch("setLogin", false);
          this.$store.dispatch("setUser");
          setTimeout(() => {
            window.location = "/";
          }, 100);
        })
        .catch();
    },
    handleChangeLanguage(lang) {
      this.$store.dispatch("setLanguage", lang);
      this.$nextTick(() => {
        window.location = "/";
      });
    }
  }
};
</script>
