const getters = {
    gLanguage: state => state.app.language,
    gSize: state => state.app.size,
    gDevice: state => state.app.device,
    gMainnav: state => state.app.mainnav,
    gNavLevel: state => state.app.navLevel,
    gNavVisible: state => state.app.navVisible,
    gSearchForm: state => state.app.searchForm,
    gSearchVisible: state => state.app.searchVisible,
    gSearchResult: state => state.app.searchResult,
    gShowSearchResult: state => state.app.showSearchResult,
    gSearchFormAction: state => state.app.searchFormAction,
    gPermission_routers: state => state.permission.routers,
    gAddRouters: state => state.permission.addRouters,
    gUser: state => state.user,
  };
  
  export default getters;