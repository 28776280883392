import router from "./router";
import Store from "./store";
import LoaderMainnav from "@/utils/loaders/LoaderMainnav";
import CheckLogin from "@/utils/CheckLogin";

//const whiteList = ["/login", "/auth-redirect"]; // no redirect whitelist

router.beforeEach((to, from, next) => {
  if (!Store.getters.gUser.loginChecked) {
    CheckLogin.check();
  }
  if (Store.getters.gMainnav.length == 0) {
    LoaderMainnav.get();
  }
  /* if (Store.getters.gFooternav.length == 0) {
    LoaderFooternav.get();
  } */
  /* if (to.meta) {
    Store.dispatch("setPageOverflow", to.meta.hasOverflow);
  } */
  next();
});

router.afterEach(() => {
  //unsetLoading();
  //NProgress.done() // finish progress bar
});
