<template>
  <div v-if="!showSearchResult" class="content__nav" :class="{ 'is-hidden': isLoading }" :data-items="navItemsCount">
      <RouterLink
        v-if="linkPrevContent && !isExternalLink(linkPrevContent.path)"
        :to="linkPrevContent.path"
        class="link--prev-content"
        :uid="linkPrevContent.meta.pageId"
        :level="linkPrevContent.meta.level"
      >
        <i class="svgicon svgicon--chevron-left" v-html="iconChevronLeft" ></i><span class="navlink__title">{{ linkPrevContent.meta.title }}</span>
      </RouterLink>
      <a v-else-if="linkPrevContent" :href="linkPrevContent.path" class="link--next-content" target="_blank">
        <i class="svgicon svgicon--chevron-left" v-html="iconChevronLeft" ></i><span class="navlink__title">{{ linkPrevContent.meta.title }}</span>
      </a>      
      <RouterLink
        v-if="linkNextContent && !isExternalLink(linkNextContent.path)"
        :to="linkNextContent.path"
        class="link--next-content"
        :uid="linkNextContent.meta.pageId"
        :level="linkNextContent.meta.level"
      >
        <span class="navlink__title">{{ linkNextContent.meta.title }}</span><i class="svgicon svgicon--chevron-right" v-html="iconChevronRight" ></i>
      </RouterLink>
      <a v-else-if="linkNextContent" :href="linkNextContent.path" class="link--next-content" target="_blank">
        <span class="navlink__title">{{ linkNextContent.meta.title }}</span><i class="svgicon svgicon--chevron-right" v-html="iconChevronRight" ></i>
      </a>
  </div>
</template>

<script>
import { isExternal } from "@/utils";
import { mapGetters } from "vuex";
import NavigationUtility from "@/utils/NavigationUtility";
import RouterLink from "@/components/RouterLink";

export default {
  name: "ContentNav",
  components: {    
    RouterLink
  },
  data() {
    return {
      iconChevronRight: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="12.8px" height="23.8px" viewBox="0 0 12.8 23.8" overflow="visible" enable-background="new 0 0 12.8 23.8" xml:space="preserve"><polyline fill="none" stroke="#4F5D73" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="0.4,23.4 12.4,11.9 0.4,0.4 "/></svg>',
      iconChevronLeft: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="12.8px" height="23.8px" viewBox="0 0 12.8 23.8" overflow="visible" enable-background="new 0 0 12.8 23.8" xml:space="preserve"><polyline fill="none" stroke="#4F5D73" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="12.4,23.4 0.4,11.9 12.4,0.4 "/></svg>',
    };
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
    /* linkPrevContent: {
      type: Object,
      default: null,
    },
    linkNextContent: {
      type: Object,
      default: null,
    } */
  },
  created() {
    NavigationUtility.getPrevNext(this.$route);
  },
  computed: {
    ...mapGetters(["gSearchResult", "gShowSearchResult"]),
    linkPrevContent() {
      return NavigationUtility.getPrevLink();
    },
    linkNextContent() {
      return NavigationUtility.getNextLink();
    },
    navItemsCount() {
      if (this.linkNextContent && this.linkPrevContent) {
        return 2;
      } else if (this.linkNextContent || this.linkPrevContent) {
        return 1;
      } else {
        return 0;
      }
    },
    searchResult() {
      return this.gSearchResult;
    },
    showSearchResult() {
      return this.gShowSearchResult;
    }     
  },
  methods: {
    isExternalLink(routePath) {
      return isExternal(routePath);
    },
  }
};
</script>
