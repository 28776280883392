<template>
  <ul v-if="items" class="subnav__items nav__items--sub" :data-level-active="activeLevel" >
    <li 
      v-for="(route, idx) in itemsToDisplay"
      v-bind:key="route.meta.uid"
      class="subnav__item"
      :data-level="route.meta.level"
      :class="{'is-active': isActive(route), 'is-last': isLast(route), 'is-show': isParent(route)}"
    >
    <RouterLink
      :to="route.path"
      :linkClass="linkClass"
      :uid="route.meta.pageId"
      :level="route.meta.level"
      @onLinkClicked="onLinkClicked"
      v-if="route.meta.dataType=='html'"
    >
      <i v-if="route.meta.level<=1 && route.meta.svgIcon" class="svgicon svgicon--page" v-html="route.meta.svgIcon" ></i><span v-else-if="route.meta.level>1" class="navlink__number">{{ formatIndex(idx) }}</span><span class="navlink__title" v-html="route.meta.title" ></span>
    </RouterLink>         

    <a 
      :href="route.path"
      v-else
      :class="linkClass"
    >
      <i v-if="route.meta.level<=1 && route.meta.svgIcon" class="svgicon svgicon--page" v-html="route.meta.svgIcon" ></i><span v-else-if="route.meta.level>1" class="navlink__number">{{ formatIndex(idx) }}</span><span class="navlink__title" v-html="route.meta.title" ></span>
    </a>            
   

    <SubNav :items="route.children" :parent="route" />
    </li>
    
  </ul>
</template>

<script>
import RouterLink from "@/components/RouterLink";

export default {
  name: "SubNav",
  components: {
    RouterLink
  },
  data() {
    return {
      linkClass: "subnav__link subnav__item--link"
    };
  },
  computed: {
    /* displayRoutes() {
      console.log('subnav items:', this.items);
      return this.items ? this.items.filter( item => item.meta && item.meta.display && item.meta.type == "mainnav" ) : [];
    }, */
    /* navitemLines() {
      if (this.displayRoutes && this.displayRoutes.length > 5) {
        return true;
      } else {
        return false;
      }
    }, */
    itemsToDisplay() {
      return this.items ? this.items.filter(item => item.meta && item.meta.pageId != this.parent.meta.pageId) : [];
    },
    matched() {
       return this.$route.matched.map(item => item.meta.pageId);
    },
    routeLevel() {
      //console.log(this.$route);
      return this.$route.meta.level;
    }
  },
  props: {
    items: {
      type: Array,
      default: null
    }, 
    parent: {
      type: Object,
      default: null
    },
    activeLevel: Number
  },
  methods: {
    isActive(route) {
      return (route && route.state?route.state.active:false);
    },
    isLast(route) {
      return (route.state && route.state.last?true:false);
    },
    isParent(route) {
      return (route.state && route.state.parent?true:false);
    },
    onLinkClicked() {
      this.$store.dispatch("toggleNav");
    },
    formatIndex(idx) {
      return (idx<9?"0"+(++idx):++idx);
    }
  }
};
</script>
