import { EventBus } from "@/bus/event-bus.js";
import { Api } from "@/api";

const VideoRenderer = {

  renderVideo(src) {
    let str = "";
    if (src.indexOf("youtube")===-1) {
      str = "<video src='" + Api.BACKEND_URL + src + "' controls playsinline width='1280px' height='720px'></video>";
    } else {
      let id = src.substr(src.indexOf("v=") + 2);
      str = "youtubevideo=" + id;
    }
    EventBus.$emit("onPopup", str);
  },

};
  
export default VideoRenderer;
