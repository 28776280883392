const PathUtility = {

  checkLinkInPath(event) {
    const path = this.getEventPath(event);
    let res=null;
    path.forEach(el => {
      if (el.nodeName && el.nodeName.toLowerCase() === "a") {
        res = el;
      }
    });
    return res;
  },
  
  getEventPath(event) {
    let res;
    if ("composedPath" in event) {
      res = event.composedPath();
    } else if ("path" in event.path) {
      res = event.path;
    } else {
      const path = [];
      let currentElem = event.target;
      while (currentElem) {
        path.push(currentElem);
        currentElem = currentElem.parentElement;
      }
      if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
        path.push(document);
      if (path.indexOf(window) === -1) path.push(window);
      res = path;
    }
    return res;
  },

};
  
export default PathUtility;