<template>
  <div class="content">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div v-if="searchResult && showSearchResult" class="wrapper-content wrapper-content--search" ref="searchContent" @click="contentElementClicked($event)" :style="wrapperContentStyle" >
      <div class="content content--search" v-html="searchResult" ></div>
    </div>
    <div v-else class="wrapper-content" v-html="mainContent" ref="content" @click="contentElementClicked($event)" :style="wrapperContentStyle" ></div>
    <ContentNav :isLoading="isLoading" ref="domContentNav" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Api } from "@/api";
import axios from "axios";
import RouterUtility from "@/utils/RouterUtility";
import PathUtility from "@/utils/PathUtility"; 
//import NavigationUtility from "@/utils/NavigationUtility";
import ContentNav from "./components/ContentNav";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VideoRenderer from "@/utils/Renderer";
import ContentProvider from "@/utils/Provider"; 

export default {
  name: "contentregular",
  components: {
    Loading,
    ContentNav
  },
  data() {
    return {
      mainContent: "",
      contentLoaded: "",
      hasPageOverflow: false,
      iconChevronRight: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="12.8px" height="23.8px" viewBox="0 0 12.8 23.8" overflow="visible" enable-background="new 0 0 12.8 23.8" xml:space="preserve"><polyline fill="none" stroke="#4F5D73" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="0.4,23.4 12.4,11.9 0.4,0.4 "/></svg>',
      iconChevronLeft: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="12.8px" height="23.8px" viewBox="0 0 12.8 23.8" overflow="visible" enable-background="new 0 0 12.8 23.8" xml:space="preserve"><polyline fill="none" stroke="#4F5D73" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="12.4,23.4 0.4,11.9 12.4,0.4 "/></svg>',
      isLoading: false,
      fullPage: true,
      loader: "bars",
      contentNavHeight: 0
    };
  },
  computed: {
    ...mapGetters(["gSize", "gUser", "gSearchVisible", "gSearchResult", "gShowSearchResult"]),
    /* linkPrevContent() {
      return NavigationUtility.getPrevLink();
    },
    linkNextContent() {
      return NavigationUtility.getNextLink();
    } */
    wrapperContentStyle() {
      //console.log("min-height: " + (this.gSize.screen.height - this.contentNavHeight - 176) + "px");
      return "min-height: " + (this.gSize.screen.height - this.contentNavHeight - 176) + "px";
    },
    searchResult() {
      return this.gSearchResult;
    },
    showSearchResult() {
      return this.gShowSearchResult;
    }
  },
  props: {},
  created() {
    //console.log("[content created] route", this.$route);
    this.hasPageOverflow = this.$store.state.app.pageOverflow;
    this.setPageSettings();
    const uri = Api.BACKEND_URL + (this.$route.path!="/home" ? (this.$route.path.indexOf("/") == 0 ? this.$route.path.substr(1) : this.$route.path) : "");
    this.getContent(uri);
  },
  mounted() {
    this.contentNavHeight = (this.$refs.domContentNav && this.$refs.domContentNav.$el)?this.$refs.domContentNav.$el.getBoundingClientRect().height:0;
  },
  destroyed() {
  },
  methods: {
    getContent(uri) {
      this.isLoading = true;
      //const uri = Api.BACKEND_URL + (this.$route.path!="/home" ? (this.$route.path.indexOf("/") == 0 ? this.$route.path.substr(1) : this.$route.path) : "");
      axios
        .create({ withCredentials: true })
        .get(uri)
        .then(response => {
            this.mainContent = ContentProvider.extractMainContent(response.data);
            this.$nextTick(() => {
              ContentProvider.prepareContent(this.$refs.content);
            });
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => (setTimeout(this.isLoading = false, 500)));
    },
    /* extractMainContent(html) {
      let str = html.substring(html.indexOf("<main"));
      str = str.substring(0, str.indexOf("</main>") + 7);
      this.mainContent = str;
      this.$nextTick(() => {
        this.prepareContent();
      });
    }, */
    contentElementClicked(event) {
      let link = PathUtility.checkLinkInPath(event);
      if (link) {
        if (this.gSearchVisible) this.$store.dispatch("toggleSearch");
        if (link.classList.contains("link--video") || link.closest('.frame-video-gallery')) {
          VideoRenderer.renderVideo(RouterUtility.getPathFromLink(link));
          event.preventDefault();
        } else if (RouterUtility.isPrevented(link)) {
          if (!RouterUtility.isSameRoute(this.$route.path, RouterUtility.getPathFromLink(link))) {
            this.$router.push(RouterUtility.getPathFromLink(link));
          }
          if (link.parentNode.classList.contains("tx-indexedsearch-title")) {
            this.$store.dispatch("showSearchResult", false);
          }
          event.preventDefault();
        }
      }      
    },
    setPageSettings() {
      /* if (this.hasPageOverflow) {
        document.body.classList.add("has--overflow");
        const root = document.getElementsByTagName("html")[0];
        root.classList.add("has--overflow");
      } */
    },
    /* prepareContent() {
      console.log("prepareContent");
      if (this.$refs && this.$refs.content) {
        let imgs = this.$refs.content.querySelectorAll('img');
        imgs.forEach(img => {
          if (img.dataset.src) {
            img.src = Api.BACKEND_URL + img.dataset.src; //img.src.replace("http://192.168.0.240:8080", "https://aht.fixit.at");
            img.classList.remove("lazyload", "loading");
          }
        });
      }     
    } */
  }
};
</script>
