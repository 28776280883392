import Vue from "vue";

const NavigationUtility = {

  //activeLevel: 0,
  navItems: undefined,
  activeItem: undefined,
  nextItem: undefined,
  prevItem: undefined,

  getActiveEntry(items, route) {
    if (items && route && route.meta) {
      var result = [];
      items.forEach((item) => {
        var found = this.findEntry(item, route.meta.pageId, result);
        if (found && result.indexOf(item.meta.pageId) == -1) {
          result.push(item.meta.pageId);
          item.state.active = true;
        }
      });
    }
  },

  findEntry(item, id, result) {
    Vue.set(item, 'state', { active: false, last: false, parent: false });
    if (item.children && item.children.length) {
      var found = false;
      if (item.children && item.children.length==1 && item.children[0].meta.pageId == item.meta.pageId) {
        //first child in route is always the parent, if only this one do nothing
      } else {
        item.children.forEach((child) => {
          if (this.findEntry(child, id, result)) {
            if (item.meta.pageId == child.meta.pageId) {
                //this found child is the same as the parent -> so parent should be last
                //because the first child will be removed ...
              item.state.last = true;
            } else {
              if (child.state.last) item.state.parent = true;
            }
            if (result.indexOf(item.meta.pageId)==-1) result.push(item.meta.pageId);
            item.state.active = true;
            
            found = true;
          }  
        });
        return found;
      }
    }    
    if (item && id && item.meta.pageId == id) {
      if (result.indexOf(item.meta.pageId)==-1) result.push(item.meta.pageId);
      item.state.active = true;
      item.state.last = true;
      return true;
    }
  },

  getHomeLink() {
    return {
      path: "/home",
      pageId: 634,
      level: 0,
      title: "Start"
    }
    /* if (items) {
      items.forEach(item => {
        if (!result && item && item.path && item.path == "/home") {
          result = {
            path: item.path,
            pageId: item.meta.pageId,
            level: item.meta.level,
            title: item.meta.title
          }
        }
      });
    } */
  },

  getBackLink(items, result) {
    if (items) {
      items.forEach(item => {
        if (!result && item && item.state && item.state.parent) {
          result = {
            path: item.path,
            pageId: item.meta.pageId,
            level: item.meta.level,
            title: item.meta.title
          }
        } else if (!result) {
          if (!result && item.children) {
            result = this.getBackLink(item.children, result);
          }
        }
      });
    }
    return result;
  },
  
  getActiveMenuLevel(items, route)  {
    if (items) {
      var level = 0;
      items.forEach((item) => {
        level = this.checkMenuLevel(item, route.meta.pageId, level);
      });
      return level;
    } else {
      return 0;
    }
  },

  checkMenuLevel(item, id, level) {
    if (item.children && item.children.length==1 && item.children[0].meta.pageId == item.meta.pageId) {
      //first child in route is always the parent, if only this one do nothing
    } else if (item.children) {
      item.children.forEach((child) => {
        level = this.checkMenuLevel(child, id, level);
       });
    }
    
    if (item && id && item.meta.pageId == id) { 
      if (item.meta.level > level) level = item.meta.level;
    }

    return level;
  },

  getPrevLink() {
    return this.prevItem;
  },

  getNextLink() {
    return this.nextItem;
  },

  getPrevNext() {
    let idx = 0;
    this.activeItem = undefined;
    this.nextItem = undefined;
    this.prevItem = undefined;

    while (!this.activeItem && idx<this.navItems.length) {
      this.checkNavItemActive(this.navItems[idx], null, idx);
      idx++;
    }
  },

  checkNavItemActive(item, parent, index) {
    if (item && item.state && item.state.last && item.state.active) {
      this.activeItem = item; 
      if (!parent && index < this.navItems.length -1) {
        this.nextItem = this.navItems[index+1];
      } else if (parent && index < parent.children.length -1) {
        this.nextItem = parent.children[index+1];
      }
      if (!parent && index > 0) {
        this.prevItem = this.navItems[index-1];
      } else if (parent && index > 0) {
        this.prevItem = parent.children[index-1];
      }
    } else if (item.state.active) {
      var idx = 0;
      while (!this.activeItem && idx<item.children.length) {
        this.checkNavItemActive(item.children[idx], item, idx);
        idx++;
      }
    }
  }



};
  
export default NavigationUtility;