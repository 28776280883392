<template>
  <div class="popup" :class="{ 'is-visible': visible, 'is-active': active }">
    <div class="popup__inner">
      <a href="#" class="link--close-popup" @click.prevent="onCloseLinkClick" >
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 19.8 19.8" overflow="visible" enable-background="new 0 0 19.8 19.8" xml:space="preserve"><line fill="none" stroke="#4F5D73" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="0.4" y1="0.4" x2="19.4" y2="19.4"/><line fill="none" stroke="#4F5D73" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="0.4" y1="19.4" x2="19.4" y2="0.4"/></svg>
      </a>
        <div class="popup__content" ref="domPopupContent" v-if="videoId">
          <div class="video-wrapper">
            <youtube :video-id="videoId" ref="youtube" @ready="youtubePlayerReady" @playing="playing"></youtube>
          </div>
        </div>
        <div v-else class="popup__content" v-html="content" ref="domPopupContent">
        </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { EventBus } from "@/bus/event-bus.js";
import VueYoutube from 'vue-youtube'

Vue.use(VueYoutube);

export default {
  name: "Popup",
  components: {},
  data() {
    return {
      content: "",
      visible: false,
      active: false,
      fadeOut: false,
      videoId: "",
    };
  },
  computed: {
    ...mapGetters(["gSize"]),
    youtubePlayer() {
      return this.$refs.youtube.player;
    }
  },
  created() {
    EventBus.$on("onPopup", (content) => {
      if (!this.fadeOut) {
        console.log("content", content);
        if (content.indexOf("youtubevideo") != -1) {
          this.videoId = content.substr(content.indexOf("=")+1);
        } else {
          this.videoId = "";
          this.content = content;
        }
        this.visible = true;
        this.$nextTick(()=> {
          this.active = true;
          if (this.videoId) {
            const content = this.$refs.domPopupContent;
            const self = this;
            if (content.querySelector("video")) {
              content.querySelector("video").addEventListener("webkitfullscreenchange", function() {
                if (!document.fullscreenElement) self.close();
              });
              content.querySelector("video").play();
            }
          }
        })
      }
    });
  },
  mounted() {
  },
  methods: {
    onCloseLinkClick(ev) {
      this.close();
      ev.preventDefault();
    },
    /* onFullscreenChange() {
      if (document.fullscreenElement) this.close;
    }, */
    close() {
      this.fadeOut = true;
      if (!this.videoId) {
        const content = this.$refs.domPopupContent;
        if (content.querySelector("video")) {
          content.querySelector("video").pause();
        }
      } else if (this.videoId) {
        this.youtubePlayer.pauseVideo();
      }
      this.active = false;
      setTimeout(() => {
        this.visible = false;
        this.fadeOut = false;
      }, 500);
    },
    youtubePlayerReady() {
      console.log('player ready', this.player);
      this.youtubePlayer.playVideo();
    },
    playing() {
      console.log('playing', this.$refs);
    }
  }
};
</script>
