import { Api } from "@/api";

const ContentProvider = {

  videoIcon: "<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='100%' height='100%' viewBox='0 0 23.8 23.8' overflow='visible' enable-background='new 0 0 23.8 23.8' xml:space='preserve'><g> <circle fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' cx='11.9' cy='11.9' r='11.5'/> <polygon fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' points='17.4,11.9 8.4,5.9 8.4,17.9'/></g></svg>",
  funfactorIcon: "<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='100%' height='100%' viewBox='0 0 22.8 22.8' overflow='visible' enable-background='new 0 0 22.8 22.8' xml:space='preserve'><g><circle fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' cx='11.4' cy='11.4' r='11'/><path fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='   M17.4,13.4c0,3.3-2.7,6-6,6s-6-2.7-6-6H17.4z'/><path fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M3.4,8.4L4,9c0.8,0.8,2,0.8,2.8,0l0.6-0.6'/><path fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M19.4,8.4L18.8,9c-0.8,0.8-2,0.8-2.8,0l-0.6-0.6'/></g></svg>",
  staminaDemandIcon: "<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='100%' height='100%' viewBox='0 0 22.8 22.8' overflow='visible' enable-background='new 0 0 22.8 22.8' xml:space='preserve'><g><circle fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' cx='11.4' cy='11.4' r='11'/><line fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' x1='7.4' y1='16.4' x2='15.4' y2='16.4'/><circle fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' cx='6.9' cy='8.9' r='1'/><circle fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' cx='15.9' cy='8.9' r='1'/><line fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' x1='7.4' y1='5.4' x2='5.4' y2='6.4'/><line fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' x1='15.4' y1='5.4' x2='17.4' y2='6.4'/></g></svg>",
  alpineFactorIcon: "<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='100%' height='100%' viewBox='0 0 23.8 23.8' overflow='visible' enable-background='new 0 0 23.8 23.8' xml:space='preserve'><g>   <circle fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' cx='11.9' cy='11.9' r='11.5'/></g><line fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' x1='11.8' y1='6.8' x2='11.8' y2='14.8'/><circle fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' cx='11.8' cy='16.8' r='0.5'/></svg>",
  feasabilityIcon: "<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='100%' height='100%' viewBox='0 0 23.8 23.8' overflow='visible' enable-background='new 0 0 23.8 23.8' xml:space='preserve'><g><polyline fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' points='18.9,6.9 11.4,18 5.9,12.5'/></g><g><circle fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' cx='11.9' cy='11.9' r='11.5'/></g></svg>",
  technicalDemandIcon: "<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='100%' height='100%' viewBox='0 0 23.8 23.8' overflow='visible' enable-background='new 0 0 23.8 23.8' xml:space='preserve'><g><polyline fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' points='6.4,22 10.4,18 10.8,17.6 	'/><polyline fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' points='2.1,17.9 4,15.9 6.6,13.3 	'/><path fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M15,6.4l-3.3,3.3c-0.4,0.4-0.4,1,0,1.4l1.4,1.4c0.4,0.4,1,0.4,1.4,0l3.3-3.3'/><path fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M17.8,9.2c1.1,2.2,0.7,5-1.2,6.8c-1.6,1.6-3.8,2.1-5.8,1.6'/><path fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M6.6,13.3c-0.5-2,0-4.2,1.6-5.8C10,5.7,12.7,5.3,15,6.4'/><circle fill='none' stroke='#4F5D73' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' cx='11.9' cy='11.9' r='11.5'/></g></svg>",

  extractMainContent(html) {
    let str = html.substring(html.indexOf("<main"));
    str = str.substring(0, str.indexOf("</main>") + 7);
    return str;
  },

  prepareContent(content) {
    if (content) {
      let imgs = content.querySelectorAll("img");
      imgs.forEach(img => {
        if (img.dataset.src) {
          img.src = Api.BACKEND_URL + img.dataset.src; //img.src.replace("http://192.168.0.240:8080", "https://aht.fixit.at");
          img.classList.remove("lazyload", "loading");
        }
      });
      let videoLinks = content.querySelectorAll("a.link--video");
      videoLinks.forEach((link) => {
        link.innerHTML = "<i class='svgicon svgicon--video'>" + this.videoIcon + "</i><span>" + link.innerHTML + "</span>";
      });
      let icon = "", iconStr = "";
      content.querySelectorAll(".rating-value").forEach(rating => {
        icon = ""; iconStr = "<span class='value'>" + rating.innerHTML + "</span><span class='icons'>";
        if (rating.classList.contains("rating-value--funfactor")) {
          icon = this.funfactorIcon;
        } else if (rating.classList.contains("rating-value--stamina-demand")) {
          icon = this.staminaDemandIcon;
        } else if (rating.classList.contains("rating-value--alpine-factor")) {
          icon = this.alpineFactorIcon;
        } else if (rating.classList.contains("rating-value--feasibility")) {
          icon = this.feasabilityIcon;
        } else if (rating.classList.contains("rating-value--technical-demand")) {
          icon = this.technicalDemandIcon;
        }
        for (let i = 0; i < rating.dataset.number; i++) {
          iconStr += icon;
        }
        rating.innerHTML = iconStr + "</span>";
      });
    }
  },

};
  
export default ContentProvider;