const RouterUtility = {

  getPathFromLink(link) {
    if (link && link.href) {
      let href = link.href.replace("http://192.168.0.14:8080", "");
      href = href.replace("https://wispowoapp.fixit.at","");
      href = href.replace("https://wispowo.app", "");
      return href;
    } else {
      return "";
    }
  },

  /*
   * isSameRoute
   * 
   * checks if the given routes are the same
   * @param string route1
   * @param string route2
   * @return true if the the routes are the same
   */ 
  isSameRoute(route1, route2) {
    return route1 == route2;
  },
  
  /*
   * isPrevented
   * 
   * checks if an event should be prevented due to external links
   * @param object link the link as dom element
   * @return true if the event should be prevented false if it's an external link
   */ 
  isPrevented(link) {
    if ((link.classList && link.classList.contains('link--download')) || link.href.indexOf("mailto:") !=- 1 || link.hostname != window.location.hostname || link.target == "_blank") {
      return false;
    } else {
      return true;
    }
  }

};
  
export default RouterUtility;