<template>
  <header class="header--back">
      <a v-if="searchResult && showSearchResult" class="link--close-search" href="#" @click.prevent="hideSearchResult" >
        <i class="svgicon svgicon--close">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 24.6 24.6" overflow="visible" enable-background="new 0 0 24.6 24.6" xml:space="preserve"><g><line fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1" y1="23.6" x2="23.6" y2="1"/><line fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1" y1="1" x2="23.6" y2="23.6"/></g></svg>
        </i>
        <span>Suche schliessen</span>
      </a>  

    <RouterLink v-else-if="backLink"
      :to="backLink.path"
      :uid="backLink.pageId"
      :level="backLink.level"
      @onLinkClicked="onLinkClicked"
    >
      <i class="svgicon svgicon--back">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32px" height="15.8px" viewBox="0 0 32 15.8" overflow="visible" enable-background="new 0 0 32 15.8" xml:space="preserve"><path fill="#D66524" d="M2.8,8.6l5.9,5.9c0.3,0.3,0.3,0.8,0,1c-0.3,0.3-0.8,0.3-1,0L0,7.8l7.6-7.6c0.3-0.3,0.8-0.3,1,0c0.3,0.3,0.3,0.8,0,1L2.8,7.1l28.5,0c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7L2.8,8.6z"/></svg>
      </i> 
      <span v-html="formatBacklinkTitle(backLink.title)" ></span>
    </RouterLink>         
  </header>
</template>

<script>

import { mapGetters } from "vuex";
import NavigationUtility from "@/utils/NavigationUtility/";

export default {
  name: "FooterNav",
  components: { },
  data() {
    return {
      linkClass: "nav__link nav__item--link",
    };
  },
  computed: {
    ...mapGetters(["gNavVisible", "gSearchResult", "gShowSearchResult"]),
    backLink() {
      var result = undefined;
      const menuLevel = NavigationUtility.getActiveMenuLevel(this.items, this.$route);
      if (menuLevel == 1) {
        result = NavigationUtility.getHomeLink();
      } else if (menuLevel != 0) {
        if (this.items) result = NavigationUtility.getBackLink(this.items, result);
      }
      return result;
    },
    searchResult() {
      return this.gSearchResult;
    },
    showSearchResult() {
      return this.gShowSearchResult;
    }    
  },
  props: {
    items: {
      type: Array,
      default: null
    }
  },
  methods: {
    onLinkClicked() {
      if (this.gNavVisible) this.$store.dispatch("toggleNav");
    },
    hideSearchResult() {
      this.$store.dispatch("showSearchResult", false);
    },
    formatBacklinkTitle(title) {
      return title.replace('<br>', ' ');
    }  
  }
};
</script>
