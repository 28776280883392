const user = {
    state: {
      login: false,
      username: "",
      realname: "",
      userid: 0,
      userInitials: "",
      loginChecked: false
    },
    mutations: {
      SET_LOGIN: (state, login) => {
        state.login = login;
      },
      SET_USERNAME: (state, username) => {
        state.username = username;
      },
      SET_REALNAME: (state, realname) => {
        state.realname = realname;
      },
      SET_USERID: (state, userid) => {
        state.userid = userid;
      },
      SET_USERINITALS: (state, initials) => {
        state.userInitials = initials;
      },
      SET_LOGINCHECKED: (state, checked) => {
        state.loginChecked = checked;
      }
    },
    actions: {
      setLogin({ commit }, login) {
        commit("SET_LOGIN", login);
      },
      setUser({ commit }, user) {
        if (user) {
          commit("SET_USERNAME", user.username);
          commit("SET_REALNAME", user.realname);
          commit("SET_USERID", user.uid);
          commit("SET_USERINITALS", user.initials);
        } else {
          commit("SET_USERNAME", "");
          commit("SET_REALNAME", "");
          commit("SET_USERID", 0);
          commit("SET_USERINITALS#", "");
        }
      },
      setLoginChecked({ commit }, checked) {
        commit("SET_LOGINCHECKED", checked);
      }
    }
  };
  
  export default user;