import { Api } from "@/api";
import axios from "axios";
import router from "@/router";
import Store from "@/store";
import Backend from "@/utils/Backend.js";

const LoaderMainnav = {
  get() {
    const uri = Backend.url() + Api.MAINNAV;
    axios
      .create({ withCredentials: true })
      .get(uri)
      .then(response => {
        const dynRoutes = response.data.menu;
        Store.dispatch("setNavLevel", 0);
        Store.dispatch("setMainnav", response.data.menu);
        Store.dispatch("GenerateRoutes", {
          routes: dynRoutes,
          nav: "mainnav"
        }).then(() => {
          router.addRoutes(Store.getters.gAddRouters);
        });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => (this.isLoading = false));
  }
};

export default LoaderMainnav;
