<template>
  <div class="wrapper-search" ref="domSearchContent" :class="searchClass">
    <div class="tx-indexedsearch-searchbox">
      <form method="post" id="tx_indexedsearch" :action="formAction" v-on:submit.prevent="onFormSubmit" v-html="searchFormContent">
      </form>  
    </div>  
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoaderSearch from "@/utils/loaders/LoaderSearch";

export default {
  name: "Search",
  components: { },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["gSearchVisible", "gSearchFormAction", "gSearchForm"]),
    searchFormContent() {
      return this.gSearchForm;
    },
    searchClass() {
      return (this.gSearchVisible?"is-visible":"");
    },
    formAction() {
      return this.gSearchFormAction;
    },
    searchActive() {
      return this.gSearchVisible === true;
    },
  },
  watch: {
    searchActive: function(val) {
      if (val && this.$refs.domSearchContent) {
        this.$nextTick(() => {
          document.getElementById("tx-indexedsearch-searchbox-sword").focus();          
        });
      } 
    },
  },
  props: {
    items: {
      type: Array,
      default: null
    }
  },
  created() {
    LoaderSearch.get();
  },
  methods: {
    onFormSubmit(ev) {
      document.querySelector(".tx-indexedsearch-searchbox-button").focus();
      LoaderSearch.post(ev.target);
      if (this.gSearchVisible) this.$store.dispatch("toggleSearch");
      this.$store.dispatch("showSearchResult", true);
      ev.preventDefault;
    }
  }
};
</script>
