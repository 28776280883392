import { Api } from "@/api";
import axios from "axios";
import Backend from "@/utils/Backend.js";
import Store from "@/store";

const LoaderSearch = {

  searchForm: "",
  dom: null,
  form: {
    action: ""
  },
  isLoading: false,
 
  get() {
    const uri = Backend.url() + Api.SEARCH;
    //console.log('LoaderSearch uri: ', uri);
    axios
      .create({ withCredentials: true })
      .get(uri)
      .then(response => {
        //console.log("response", response);
        let temp = this.extractMainContent(response.data);
        this.dom = document.createElement("div");
        this.dom.innerHTML = temp;
        this.prepareMainContent();
        this.setFormValues();
        this.searchForm = this.dom.innerHTML;
        Store.dispatch("setSearchForm", this.searchForm);
      })
      .catch(error => {
        console.log(error);
      });
  },

  post(form) {
    //console.log("SEARCH onFormSubmit:", form.querySelectorAll("input"));
    const formData = new FormData();
    form.querySelectorAll("input").forEach((input) => {
      if (input.name.indexOf("numberOfResults")!=-1) input.value = 100;
      formData.append(input.name, input.value);
    });
    var action = form.action.replace("http://192.168.0.14:8080/", "");
    action = action.replace("https://appwispowo.fixit.at/", "");
    action = action.replace("https://wispowoapp.fixit.at/", "");
    action = action.replace("https://wispowo.app", "");
    const uri = Backend.url() + action;
    //console.log("uri", uri);
    axios
      .create({ withCredentials: true })
      .post(uri, formData)
      .then((response) => {
        //console.log("response", response);
        let temp = this.extractMainContent(response.data);
        this.setFormValues();
        Store.dispatch("setSearchResult", temp);
        //console.log('temp', temp);
      })
      .catch((error) => {
        console.log("error searchform send", error);
      });    
  },

  extractMainContent(html) {
    let str = html.substring(html.indexOf("<main"));
    str = str.substring(0, str.indexOf("</main>") + 7);
    return str;
  },

  prepareMainContent() {
    Array.from(this.dom.querySelectorAll(".svg-icon--search")).forEach((icon) => {
      icon.innerHTML = "<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='34px' height='34px' viewBox='0 0 34 34' overflow='visible' enable-background='new 0 0 34 34' xml:space='preserve'><g><path fill='none' stroke='#FFFFFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M1,14.1c0,7.2,5.9,13.1,13.1,13.1c7.2,0,13.1-5.9,13.1-13.1C27.2,6.9,21.3,1,14.1,1C6.9,1,1,6.9,1,14.1z'/><line fill='none' stroke='#FFFFFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' x1='33' y1='33' x2='23.3' y2='23.3'/><path fill='none' stroke='#FFFFFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M15.1,7c-4.3,0-7.8,3.5-7.8,7.8'/></g></svg>";
    });
  },

  setFormValues() {
    const form = this.dom.querySelector('form');
    this.searchForm = form.innerHTML;
    this.form.action = form.action;
    Store.dispatch("setSearchFormAction", this.form.action);
  }

};

/* function get() {
}; */





export default LoaderSearch;
